import React from 'react'
import './css/App.css'
// import { BrowserRouter as Router, Route, Link, Switch, useHistory } from "react-router-dom";

// Import Main React Component
import Main from './components/Main'

export default function App() {
  return <Main />
}
